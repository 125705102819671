import "./main.scss";
import "swiper/css"
import "swiper/css/effect-fade";

window.addEventListener("load", () => {
    import("htmx.org").then(htmx => {
        htmx.config.scrollIntoViewOnBoost = false
    })
})

import "./js/alpine";
import "./js/alpine/map";

import "img-comparison-slider"