import Alpine from "alpinejs";
import intersect from '@alpinejs/intersect'
import collapse from '@alpinejs/collapse'
 
Alpine.plugin(intersect)
Alpine.plugin(collapse)

import PhoneInputMask from "./PhoneInputMask";
Alpine.data("PhoneInputMask", PhoneInputMask);

import SimpleSlider from "./SimpleSlider";
Alpine.data("SimpleSlider", SimpleSlider);

import FancyboxGallery from "./FancyboxGallery";
Alpine.data("FancyboxGallery", FancyboxGallery);

import Modal from "../../components/Modal";
Alpine.data("Modal", Modal)

import CustomScrollbar from "../../components/CustomScrollbar"
Alpine.data("CustomScrollbar", CustomScrollbar)

import MainSlider from "./MainSlider";
Alpine.data("MainSlider", MainSlider)

import PortfolioSlider from "./PortfolioSlider";
Alpine.data("PortfolioSlider", PortfolioSlider)

import SpecSlider from "./SpecSlider";
Alpine.data("SpecSlider", SpecSlider)

import CertificatesSlider from "./CertificatesSlider"
Alpine.data("CertificatesSlider", CertificatesSlider)

import DocumentsSlider from "./DocumentsSlider"
Alpine.data("DocumentsSlider", DocumentsSlider)

import ContentTextFixes from "./ContentTextFixes"
Alpine.data("ContentTextFixes", ContentTextFixes)

Alpine.store("mobileMenu", {
    open: false,
});

Alpine.start();
