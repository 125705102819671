export default () => ({
    async init(){
        const { default: Swiper } = await import("../libs/Swiper");
        
        new Swiper(this.$root, {
            speed: 400,
            autoHeight: true,
            spaceBetween: 30,
            effect: 'fade',
            pagination: {
                el: this.$refs.pagination,
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + `0${index+1}` + '</span>';
                }
            }
        });
    }
})