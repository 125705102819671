export default () => ({
    init(){
        this.responsiveTable()
    },
    responsiveTable(){
        const tables = this.$root.querySelectorAll('table')
        tables.forEach(table => {
            const scrollbarRoot = document.createElement('div')
            scrollbarRoot.className = 'custom-scrollbar responsive-table'
            scrollbarRoot.setAttribute('x-data', 'CustomScrollbar')

            table.parentNode.insertBefore(scrollbarRoot, table)

            const scrollbarContainer = document.createElement('div')
            scrollbarContainer.className = 'custom-scrollbar__container'
            scrollbarContainer.setAttribute('x-ref', 'container')
            scrollbarRoot.appendChild(scrollbarContainer)

            const scrollbarContent = document.createElement('div')
            scrollbarContent.className = 'custom-scrollbar__content'
            scrollbarContainer.appendChild(scrollbarContent)

            
            scrollbarContent.appendChild(table)            
        })
    }
})