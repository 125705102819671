const maps = document.querySelectorAll('.map');
if( maps.length ) {
	document.addEventListener('DOMContentLoaded', () => {
		for (let i = 0; i < maps.length; i++){
			function init() {
				// Задаём точки мультимаршрута.
				let mapLat = Number(maps[i].getAttribute('data-lat'));
				let mapLng = Number(maps[i].getAttribute('data-lng'));
				var multiRoute = new ymaps.multiRouter.MultiRoute({
					referencePoints: [
						[55.808989, 37.462704],
						[mapLat, mapLng]
					],
					params: {
						routingMode: 'pedestrian',
						results: 1
					}
				}, {
					boundsAutoApply: true
				});
			
				// Создаем карту с добавленной на нее кнопкой.
				var myMap = new ymaps.Map('map', {
					center: [mapLat, mapLng],
					zoom: 17,
				});
			
				// Добавляем мультимаршрут на карту.
				myMap.geoObjects.add(multiRoute);
			}
			
			ymaps.ready(init);	
		}
	})
}