export default () => ({
    async init(){
        const { default: Swiper } = await import("../libs/Swiper");
        
        new Swiper(this.$refs.swiper, {
            speed: 500,
            slidesPerView: 2,
            spaceBetween: 30,
            navigation: {
                prevEl: this.$refs.prev,
                nextEl: this.$refs.next
            },
        });
    }
})