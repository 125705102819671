import MicroModal from "micromodal";
import "./modal.scss"

const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

const modalConfig = {
    onShow: () => {
        applyOffset(scrollbarWidth);
    },
    onClose: () => {
        applyOffset(0)
    },
    awaitOpenAnimation: true,
    awaitCloseAnimation: true,
    disableFocus: true,
    disableScroll: true,
};

const applyOffset = (offset) => {
    document.body.style.paddingRight = `${offset}px`;
};

const showModal = (modalID) => MicroModal.show(modalID, modalConfig)
const closeModal = (modalID) => MicroModal.close(modalID, modalConfig)

export default () => ({
    close(modalID) {
        if (modalID == "self") {
            const modal = this.$root.closest(".modal")
            if (!modal)
                return

            modalID = modal.id
        }

        closeModal(modalID)
    },
    open(modalID) {
        if (modalID == "self") {
            const modal = this.$root.closest(".modal")
            if (!modal)
                return

            modalID = modal.id
        }

        showModal(modalID)
    }
})
