export default () => ({
    async init(){
        if (matchMedia("(max-width: 575px)").matches)
            return

        const { default: Swiper } = await import("../libs/Swiper");
        
        new Swiper(this.$refs.swiper, {
            speed: 500,
            spaceBetween: 22,
            navigation: {
                prevEl: this.$refs.prev,
                nextEl: this.$refs.next
            },
            breakpoints: {
                1280: {
                    slidesPerView: 3,
                    spaceBetween: 52,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 34,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 28,
                },
                576: {
                    slidesPerView: 2
                }
            }
        });
    }
})