export default () => ({
    async init(){
        const { default: Swiper } = await import("../libs/Swiper");
        
        new Swiper(this.$refs.swiper, {
            speed: 500,
            spaceBetween: 8,
            navigation: {
                prevEl: this.$refs.prev,
                nextEl: this.$refs.next
            },
            breakpoints: {
                992: {
                    slidesPerView: 4,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 3,
                },
                576: {
                    slidesPerView: 2,
                }
            }
        });
    }
})